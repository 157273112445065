@import 'global';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
	font-family: 'Bobby Jones Soft';
	src: url('../font/bobbyjonesSoft/BobbyJonesSoft-Regular.woff2') format('woff2'),
	url('../font/bobbyjonesSoft/BobbyJonesSoft-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

.ff-roboto{ font-family:'Roboto'; }
.ff-bobby { font-family: 'Bobby Jones Soft';}

.fw-thin{ font-weight: 100; }
.fw-light{ font-weight: 300; }
.fw-semibold{ font-weight: 600; }
.fw-bold{ font-weight: bold; }
.fw-extrabold{ font-weight: 800; }
.fw-black{ font-weight: 900; }

.fs-uppercase{ text-transform: uppercase; }
.fs-underline{ text-decoration: underline; }
.fs-italic{ font-style: italic; }

.fc-white{	color : $white-color; }

/*génération des taille de polices mobile*/

$mobile_font_size : 35,24,20;

@each $i in $mobile_font_size {

	.fs-#{$i}{
		font-size : get-mobile-vw(1px*$i);
	}
}

/*génération des taille de polices small desktop */
@media screen and (min-width : map-get($grid-breakpoints, lg)) and (max-width : ($desktop_base_size - 1) * 1px ){

	$desktop_font_size : 20;

	@each $i in $desktop_font_size {

		.fs-d-#{$i}{
			font-size : get-desktop-vw(1px*$i);
		}
	}

}

/*génération des taille de polices desktop */
@media screen and (min-width : $desktop_base_size * 1px){

	$desktop_font_size : 20;

	@each $i in $desktop_font_size {

		.fs-d-#{$i}{
			font-size : 1px*$i;
		}
	}

}